:root {
  --header-height-home: 140px;
  --header-height: 60px;
  --footer-height: 350px;
}
@media (max-width: 768px) {
  :root {
    --header-height-home: 60px;
  }
}
