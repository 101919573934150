@font-face {
  font-family: "Gimbal";
  src: local("Gimbal"),
    url("./fonts/GimbalextendedRegular-7B0xP.otf") format("opentype");
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./fonts/Roboto-Medium.ttf") format("truetype");
}

body {
  font-family: "Verdana", sans-serif;
}
